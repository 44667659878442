import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { dummyProfile } from "./common-images";

import { isNotEmpty } from "../utils/utils";

const Markdown = ({ data }) => {
  return <ReactMarkdown children={data} remarkPlugins={[remarkGfm]} />;
};

const PeopleBoards = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Boards Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>{item?.name}</h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleInstitutes = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Institutes Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>{item?.name}</h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
              {item?.website && (
                <p className="people__website">
                  Website :&nbsp;
                  <a href={item?.website} target="_blank" rel="noreferrer">
                    {item?.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleMissions = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Mission Fields Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>{item?.name}</h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleOutstations = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Outstations Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>{item?.name}</h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleDistricts = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Districts Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>
              {item?.church_name} - {item?.name}
            </h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
              {item?.location && (
                <p className="people__location">
                  Location :&nbsp;
                  <a href={item?.location} target="_blank" rel="noreferrer">
                    click here
                  </a>
                </p>
              )}
              {item?.website && (
                <p className="people__website">
                  Website :&nbsp;
                  <a href={item?.website} target="_blank" rel="noreferrer">
                    {item?.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeoplePastorates = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Pastorates Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>
              {item?.church_name} - {item?.name}
            </h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
              {item?.location && (
                <p className="people__location">
                  Location :&nbsp;
                  <a href={item?.location} target="_blank" rel="noreferrer">
                    click here
                  </a>
                </p>
              )}
              {item?.website && (
                <p className="people__website">
                  Website :&nbsp;
                  <a href={item?.website} target="_blank" rel="noreferrer">
                    {item?.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleHeads = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Heads - Additional Duties</h4>
      <ul className="people__category-list">
        {data?.map(item => {
          return (
            <li className="people__category-list-item" key={item?.uuid}>
              {item?.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Email = ({ data }) => {
  return (
    <div className="people__email">
      {data?.split(",").map(email => {
        return (
          <a
            className="people__email-item me-2"
            href={`mailto:${email}`}
            key={email}
          >
            {email}
          </a>
        );
      })}
    </div>
  );
};

const Phone = ({ data }) => {
  return (
    <div className="people__phone">
      {data?.split(",").map(mob => {
        return (
          <a className="people__phone-item me-2" href={`tel:${mob}`} key={mob}>
            {mob}
          </a>
        );
      })}
    </div>
  );
};

const Address = ({ data }) => {
  return (
    <div className="people__address">
      <ReactMarkdown children={data} remarkPlugins={[remarkGfm]} />
    </div>
  );
};

const PeopleDepartments = ({ data }) => {
  return (
    <div className="people__category">
      <h4>Departments Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>
              {item?.church_name} - {item?.name}
            </h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
              {item?.location && (
                <p className="people__location">
                  Location :&nbsp;
                  <a href={item?.location} target="_blank" rel="noreferrer">
                    click here
                  </a>
                </p>
              )}
              {item?.website && (
                <p className="people__website">
                  Website :&nbsp;
                  <a href={item?.website} target="_blank" rel="noreferrer">
                    {item?.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleOrganizations = ({ data }) => {
  return (
    <div className="people__category">
      <h4>organizations Associated</h4>
      {data?.map(item => {
        return (
          <div className="people__category-item" key={item?.uuid}>
            <h5>{item?.name}</h5>
            {item?.image_url && (
              <div className="people__category-item-img">
                <img src={item?.image_url} alt={item?.name} />
              </div>
            )}
            <div className="people__category-item-detail">
              <Markdown data={item?.markdown_title} />
              <Markdown data={item?.markdown} />
              <span className="people__category-item-detail-pivot mb-2 d-block">
                Designation : {item?.pivot?.designation}
              </span>
              <Address data={item?.address} />
              <Email data={item?.email_field} />
              <Phone data={item?.mobile_field} />
              {item?.location && (
                <p className="people__location">
                  Location :&nbsp;
                  <a href={item?.location} target="_blank" rel="noreferrer">
                    click here
                  </a>
                </p>
              )}
              {item?.website && (
                <p className="people__website">
                  Website :&nbsp;
                  <a href={item?.website} target="_blank" rel="noreferrer">
                    {item?.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PeopleV2 = ({ data }) => {
  console.log(data);
  return (
    <div className="people">
      <div className="people__detail">
        {isNotEmpty(data?.boards) && <PeopleBoards data={data?.boards} />}
        {isNotEmpty(data?.institutes) && (
          <PeopleInstitutes data={data?.institutes} />
        )}
        {isNotEmpty(data?.mission_fields) && (
          <PeopleMissions data={data?.mission_fields} />
        )}
        {isNotEmpty(data?.outstations) && (
          <PeopleOutstations data={data?.outstations} />
        )}
        {isNotEmpty(data?.districts) && (
          <PeopleDistricts data={data?.districts} />
        )}
        {isNotEmpty(data?.pastorates) && (
          <PeoplePastorates data={data?.pastorates} />
        )}
        {isNotEmpty(data?.heads) && <PeopleHeads data={data?.heads} />}
        {isNotEmpty(data?.organizations_list) && (
          <PeopleOrganizations data={data?.organizations_list} />
        )}
        {isNotEmpty(data?.departments_list) && (
          <PeopleDepartments data={data?.departments_list} />
        )}
      </div>
      <div className="people__img">
        <img
          src={data?.image_url ? data?.image_url : dummyProfile}
          alt={data?.name}
        />
        <h1>{data?.name}</h1>
        <Address data={data?.address} />
        <Email data={data?.email_field} />
        <Phone data={data?.mobile_field} />
      </div>
    </div>
  );
};

export { PeopleV2 };
