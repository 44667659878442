import React from "react";

import { Seo } from "../../components";
import Layout from "../../components/layout";
import { PeopleV2 } from "../../components/people2";
import { BACKEND_BASE_URL } from "../../constant/constants";

const PeopleByUUID = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.name}
        description={serverData.name}
        image_url={serverData.image_url}
      />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PeopleV2 data={serverData} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const res = await fetch(
      BACKEND_BASE_URL + "people-by-uuid/" + context.params.uuid
    );
    if (!res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: await res.json(),
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default PeopleByUUID;
